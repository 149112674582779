import NewUpdate from "./components/Shared/newUpdate.tsx";

function App() {
  // Check for updates

  return (
    <NewUpdate
      message="Update"
      message2="A newer version of app is available please update your app now"
    />
  );
}

export default App;
