// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

export default function NewUpdate({ message, message2 }: { message: string; message2: string }) {
  const isIOSApp = () => {
    return /(iPhone|iPod|iPad)/.test(navigator.userAgent) && !window.MSStream;
  };

  const isAndroidApp = () => {
    return /Android/.test(navigator.userAgent) && !window.MSStream;
  };
  const onButtonClick = async () => {
    let url = "https://apps.apple.com/gb/app/cliq-connect-meet-people/id6451363528";
    if (isIOSApp()) {
      url = "https://apps.apple.com/gb/app/cliq-connect-meet-people/id6451363528";
    }
    if (isAndroidApp()) {
      url = "https://play.google.com/store/apps/details?id=com.thecliq.app";
    }
    window.open(url, "_blank");
  };

  const styles = {
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 999,
    },
    alert: {
      backgroundColor: "white",
      padding: "20px",
      borderRadius: "5px",
      textAlign: "center",
      maxWidth: "80%",
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      fontSize: "14px",
    },
    button: {
      width: "150px",
      margin: "auto",
      borderRadius: "10px",
      height: "40px",
      backgroundColor: "#EEE9F9",
      border: "none",
      outline: "none",
      fontSize: "16px",
    },
  };

  return (
    <div id="chiku" style={styles.overlay}>
      <div style={styles.alert}>
        <div>
          <div style={{ marginBottom: "5px" }}>{message}</div>
          <div>{message2}</div>
        </div>
        <button style={styles.button} onClick={onButtonClick}>
          Update Now
        </button>
      </div>
    </div>
  );
}
